import * as React from "react";
import {graphql, PageProps} from "gatsby";

import Layout from "../../components/layout";
import Hero from '../../components/hero';
import TrustpilotCarousel from "../../components/trustpilot/trustpilot-carousel";
import LocationsFilter from "../../components/locations-filter";
import TemplateHead from "../../components/template-head";

type DataProps = {
  allLocationsByType: any,
  slug: any,
  currentType: any
}

export default function Locations({location, data: {currentType, allLocationsByType}}: PageProps<DataProps>) {

  return (
    <Layout>
      <Hero
        sectionClassName="hero-section hero-section--locations pl-4 pr-4"
        bgImageClassName="hero-image hero-image--blog"
        contentClassName="hero-content hero-content--blog"
        altImages="Fenix Funeral Directors"
        imageGlow={"/images/glow.png"}
        breadcrumb={[{name: 'Fenix Home', slug: '/'}, {
          name: 'Locations',
          slug: '/locations/'
        }, {name: currentType.title + ' in UK', slug: '/locations/' + currentType.slug + '/'}]}
      >
      </Hero>
      <LocationsFilter
        locationsData={allLocationsByType.nodes}
        contextDataType={currentType}
        prevLocation={location.state ? location.state : null}
      />
      <TrustpilotCarousel/>
    </Layout>
  )
}

export function Head({data: {allLocationsByType, currentType}}) {

  const pageTitle = '▷ ' + allLocationsByType.nodes.length + ' ' + currentType.slug + ' in UK  • Fenix Funeral Directors'
  const pageDescription = 'Arrange funeral in UK → For more information about ' + allLocationsByType.nodes.length + ' ' + currentType.title.toLowerCase() + ' for funeral in UK ✓ We help you with bookings | Fenixfuneral.co.uk'
  const pageLink = 'https://fenixfuneral.co.uk/locations/' + currentType.slug + '/'

  return (
    <TemplateHead
      title={pageTitle}
      description={pageDescription}
      shortlink={pageLink}
      alternate={pageLink}
      canonical={pageLink}
      og={{
        url: pageLink,
        title: pageTitle,
        description: pageDescription
      }}
      ldjsonbreadcrumb={`{
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
            "@type": "ListItem",
            "position": "1",
            "name": "Fenix Home","item": "https://fenixfuneral.co.uk/"
            },
            {
            "@type": "ListItem",
            "position": "2",
            "name": "Locations","item": "https://fenixfuneral.co.uk/locations/"
            },
            {
            "@type": "ListItem",
            "position": "3",
            "name": "${currentType.title} in UK","item": "https://fenixfuneral.co.uk/locations/${currentType.slug}/"
            }
          ]
        }`
      }
    />
  );
}

export const pageQuery = graphql`
    fragment locationData on STRAPI_LOCATIONConnection {
        nodes {
            strapi_id
            title
            slug
            slugFull
            images {
                awsThumbnail
                awsImage
            }
            type {
                slug
                title
            }
            hub {
                title
                urlPart
            }
            city {
                title
                cityUrl
            }
        }
    }
    query ($type__slug: String) {
        allLocationsByType: allStrapiLocation(
            filter: {type: {slug: {eq: $type__slug}}}
            sort: {fields: title, order: ASC}) {
            ...locationData
        }
        currentType: strapiLocationtype(slug: {eq: $type__slug}) {
            title
            slug
            strapi_id
        }
    }
`;